import { AxiosInternalService, AxiosService } from 'api/axiosService';
import { ApiResponse, SiteDetailsData, PaginatedApiResponse } from './types';
import { useQuery } from '@tanstack/react-query';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';
import { components } from 'openapi-types';

export interface Response<T> {
  data: {
    viewer: {
      zones: [T];
    };
  };
}

export interface SiteMetrics extends Response<SiteMetricsZoneData> {
  dataTransferTotal: number;
  requestsTotal: number;
  success: boolean;
}

interface SiteMetricsZoneData {
  data_transferTotals: Array<{
    dimensions: {
      ts: string;
    };
    sum: {
      edgeResponseBytes: number;
    };
  }>;
  requestsTotals: Array<{
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
}

export const useSiteMetrics = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/requests?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteMetrics${data.id}`],
    queryFn: async () => await AxiosInternalService.get<SiteMetrics>(url),
  });
};

export interface SiteCdnEdgeOrigin extends Response<SiteCdnData> {
  serveByEdgeTotal: number;
  serveByOriginTotal: number;
  success: boolean;
}

interface SiteCdnData {
  servedByEdge: Array<{
    avg: {
      sampleInterval: number;
    };
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
  servedByOrigin: Array<{
    avg: {
      sampleInterval: number;
    };
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
}

export const useSiteCdnEdgeOrigin = (id: string, duration: string) => {
  const url = `reporting/sites/${id}/cdn/requests?duration=${duration}&req_type=edgeOrigin`;

  return useQuery({
    queryKey: [`siteCdnEdgeOrigin${id}`],
    queryFn: async () => await AxiosService.get<SiteCdnEdgeOrigin>(url),
  });
};

export interface SiteCacheStatus
  extends Response<{
    cacheStatus: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      dimensions: {
        cacheStatus: string;
      };
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheStatus = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-status?duration=${data.duration}`;

  return useQuery({
    queryKey: ['siteCacheStatus', data.id, data.duration],
    queryFn: async () => await AxiosService.get<SiteCacheStatus>(url),
    retry: 0,
    gcTime: 0,
  });
};

export interface SiteCacheContent
  extends Response<{
    cacheStatusByContentType: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      dimensions: {
        cacheStatus: string;
        edgeResponseContentTypeName: string;
      };
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheContent = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-content?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteCacheContent${data.id}`],
    queryFn: async () => await AxiosService.get<SiteCacheContent>(url),
  });
};

export interface SiteCacheTopDatum {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
  };
  sum: {
    edgeResponseBytes: number;
  };
}

export interface SiteCacheTop
  extends Response<{
    topContentTypes: Array<SiteCacheTopDatum>;
    topCountries: Array<SiteCacheTopDatum>;
    topDeviceTypes: Array<SiteCacheTopDatum>;
    topHosts: Array<SiteCacheTopDatum>;
    topQueryStrings: Array<SiteCacheTopDatum>;
    topStatusCodes: Array<SiteCacheTopDatum>;
    topUriPaths: Array<SiteCacheTopDatum>;
    total: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheTop = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-top?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteCacheTop${data.id}`],
    queryFn: async () => await AxiosService.get<SiteCacheTop>(url),
  });
};
export interface FirewallSeries {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
    ts: string;
  };
}

export interface EventZone {
  firewallEventsAdaptiveGroups: Array<FirewallAdaptiveGroups>;
}
export interface FirewallAdaptiveGroups {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    source: string;
  };
}

export const useGetFirewallEvents = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafFirewallEvents-${siteId}`],

    queryFn: async () =>
      AxiosService.get(`reporting/sites/${siteId}/waf/firewall-events?duration=${duration}`),
  });
};

export const useGetWAFEventReports = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventServices-${siteId}`],

    queryFn: async () =>
      AxiosService.get<Response<EventZone>>(
        `reporting/sites/${siteId}/waf/events-services?duration=${duration}`
      ),
  });
};

export interface SourceZone {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
  };
}
export interface EventSourceZones {
  topASNs: SourceZone[];
  topCountries: SourceZone[];
  topFirewallRules: SourceZone[];
  topHosts: SourceZone[];
  topHttpMethods: SourceZone[];
  topIPs: SourceZone[];
  topRateLimitRules: SourceZone[];
  topUriPaths: SourceZone[];
  topUserAgents: SourceZone[];
  topWAFRules: SourceZone[];
  total: [
    {
      avg: {
        sampleInterval: number;
      };
      count: number;
    },
  ];
}

export const useGetEventSources = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventSources-${siteId}`],

    queryFn: async () =>
      AxiosService.get<Response<EventSourceZones>>(
        `reporting/sites/${siteId}/waf/events-source?duration=${duration}`
      ),
  });
};

export interface EventTimeReportResponse extends Response<EventTimeReport> {
  total: [
    {
      avg: {
        sampleInterval: number;
      };
      count: number;
    },
  ];
}

export interface FormattedEventTimeReports {
  activity: EventTimeReport[];
  total: EventTimeTotal;
}

export type EventTimeTotal = [
  {
    avg: {
      sampleInterval: number;
    };
    count: number;
  },
];
export interface EventTimeReport {
  action: string;
  clientASNDescription: string;
  clientAsn: string;
  clientCountryName: string;
  clientIP: string;
  clientRequestHTTPHost: string;
  clientRequestHTTPMethodName: string;
  clientRequestHTTPProtocol: string;
  clientRequestPath: string;
  clientRequestQuery: string;
  datetime: string;
  matchIndex: number;
  metadata: EventTimeMetaData[];
  rayName: string;
  ruleId: string;
  sampleInterval: number;
  source: string;
  userAgent: string;
}

export type ReportTypes =
  | 'action'
  | 'clientASNDescription'
  | 'clientAsn'
  | 'clientCountryName'
  | 'clientIP'
  | 'clientRequestHTTPHost'
  | 'clientRequestHTTPMethodName'
  | 'clientRequestHTTPProtocol'
  | 'clientRequestPath'
  | 'clientRequestQuery'
  | 'datetime'
  | 'matchIndex'
  | 'metadata'
  | 'rayName'
  | 'ruleId'
  | 'sampleInterval'
  | 'source'
  | 'userAgent';

interface EventTimeMetaData {
  key: string;
  value: string;
}

export const useGetEventTimeReport = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventTimeReports-${siteId}`],

    queryFn: async () =>
      AxiosService.get(`reporting/sites/${siteId}/waf/events?duration=${duration}`),
  });
};

export type SiteVisitors = {
  total: number;
  visitors: Array<{
    date: string;
    visitors: number;
  }>;
};

export const useGetSiteVisitors = (siteId: string, startDate: string, endDate: string) => {
  return useQuery({
    queryKey: [`siteVisitors-${siteId}-${startDate}-${endDate}`],

    queryFn: async () =>
      AxiosService.get<ApiResponse<SiteVisitors>>(
        `sites/${siteId}/visitors?date_from=${startDate}&date_to=${endDate}`
      ),

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

export type Event = components['schemas']['Event'];

export const useActivityLog = (siteId: string, state: PaginatedListState, enabled: boolean) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['site-logs', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListEventsResponse']>(
        `sites/${siteId}/activity/events?${serializedParams}`
      ),
    enabled,
    retry: 0,
    gcTime: 0,
  });
};

export type AccessLogRow = NonNullable<
  components['schemas']['ListAccessLogsResponse']['result']
>[number];

export const useAccessLogs = (
  siteId: string,
  domain: string | undefined,
  state: PaginatedListState,
  enabled = true
) => {
  const serializedParams = `${serializeParamsForQuery(state)}&domain=${domain}`;

  return useQuery({
    queryKey: ['site-access-logs', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListAccessLogsResponse']>(
        `sites/${siteId}/access_logs?${serializedParams}`
      ),
    enabled: !!domain && enabled,
    retry: 0,
    gcTime: 0,
  });
};

type SiteBandwidthUsage = {
  data: {
    viewer: {
      zones: Array<{
        series: Array<{
          avg: {
            sampleInterval: number;
          };
          count: number;
          dimensions: {
            ts: string;
          };
          sum: {
            edgeResponseBytes: number;
            visits: number;
          };
        }>;
      }>;
    };
    success: boolean;
  };
};

// Bandwidth Metrics
export const useSiteBandwidthUsage = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/bandwidth/usage?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteBandwidthUsage${data.id}-${data.duration}`],
    queryFn: async () => await AxiosService.get<SiteBandwidthUsage>(url),
  });
};

export type ReportingTotalRequests = components['schemas']['TotalRequestsResponse'];

export const useSiteReportingTotalRequests = (siteId: string | number, duration: string) => {
  return useQuery({
    queryKey: ['site', 'total-requests', siteId, duration],
    queryFn: async () =>
      await AxiosService.get<ReportingTotalRequests>(
        `sites/${siteId}/reporting/total_requests?duration=${duration}`
      ),
  });
};

export type SiteBandwidthTopUsageMetric = {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
    description: string;
  };
  sum: {
    edgeResponseBytes: number;
    visits: number;
  };
};

export type SiteBandwidthTopUsage = {
  data: {
    viewer: {
      zones: Array<{
        __typename: string;
        countries: Array<SiteBandwidthTopUsageMetric>;
        methods: Array<SiteBandwidthTopUsageMetric>;
        topASNs: Array<SiteBandwidthTopUsageMetric>;
        topBrowsers: Array<SiteBandwidthTopUsageMetric>;
        topCacheStatuses: Array<SiteBandwidthTopUsageMetric>;
        topClientIPs: Array<SiteBandwidthTopUsageMetric>;
        topColoCodes: Array<SiteBandwidthTopUsageMetric>;
        topDeviceTypes: Array<SiteBandwidthTopUsageMetric>;
        topEdgeStatusCodes: Array<SiteBandwidthTopUsageMetric>;
        topHTTPProtocolVersions: Array<SiteBandwidthTopUsageMetric>;
        topHosts: Array<SiteBandwidthTopUsageMetric>;
        topOSs: Array<SiteBandwidthTopUsageMetric>;
        topOriginStatusCodes: Array<SiteBandwidthTopUsageMetric>;
        topPaths: Array<SiteBandwidthTopUsageMetric>;
        topReferers: Array<SiteBandwidthTopUsageMetric>;
        topUserAgents: Array<SiteBandwidthTopUsageMetric>;
        topXRequestedWith: Array<SiteBandwidthTopUsageMetric>;
        total: Array<Omit<SiteBandwidthTopUsageMetric, 'dimensions'>>;
      }>;
    };
    success: boolean;
  };
};

export const useSiteBandwidthTopUsage = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/bandwidth/top-usage?duration=${data.duration}`;

  return useQuery({
    queryKey: ['siteBandwidthTopUsage', data.id, data.duration],
    queryFn: async () => await AxiosService.get<SiteBandwidthTopUsage>(url),
  });
};

type SiteBandwidth = {
  usages: {
    bandwidth: number;
    date: string;
  }[];
  total: number;
};

export const useSiteBandwidth = (
  data: SiteDetailsData & { startDate: string; endDate: string }
) => {
  const url = `sites/${data.id}/reporting/bandwidth?date_from=${data.startDate}&date_to=${data.endDate}`;

  return useQuery({
    queryKey: [`siteBandwidth${data.id}`],
    queryFn: async () => await AxiosService.get<ApiResponse<SiteBandwidth>>(url),
  });
};
