import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, SiteDetailsData } from './types';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';

export type FtpAccount = components['schemas']['FTPAccount'];

export const useFtpAccounts = (siteId: string, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['ftpAccounts', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListFtpAccountsResponse']>(
        `sites/${siteId}/ftp/accounts?${serializedParams}`
      ),

    retry: 0,
    gcTime: 0,
  });
};

export interface CreateFtpAccountData {
  domain: string;
  username: string;
  password: string;
  homedir: string;
  quota: number;
}

export const useCreateFtpAccount = (data: SiteDetailsData) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createFtpAccount'],

    mutationFn: async (body: CreateFtpAccountData) =>
      await AxiosService.post<ApiResponse<FtpAccount>>(`sites/${data.id}/ftp/accounts`, body),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['ftpAccounts', data.id],
      });
    },
  });
};

export interface UpdateFtpAccountData {
  new_password?: string;
  homedir?: string;
}

export const useUpdateFtpAccount = (data: SiteDetailsData & { username: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateFtpAccount'],

    mutationFn: async (body: UpdateFtpAccountData) =>
      await AxiosService.patch<ApiResponse<FtpAccount[]>>(
        `sites/${data.id}/ftp/accounts?username=${data.username}`,
        body
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['ftpAccounts', data.id],
      });
    },
  });
};

interface DeleteFtpAccountData {
  username: string;
}

export const useDeleteFtpAccount = (data: SiteDetailsData) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['deleteFtpAccount'],

    mutationFn: async (body: DeleteFtpAccountData) =>
      await AxiosService.delete<ApiResponse<any>>(`sites/${data.id}/ftp/accounts`, {
        data: body,
      }),

    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['ftpAccounts', data.id],
      }),
  });
};
